import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';

import MaterialReactTable from 'material-react-table';

import {
  AddonTableRow,
  DealerAdminFormValues,
} from 'pages/administration/dealers/dealer-admin-form/DealerAdminFormService';

import { useAddonsTable } from 'components/administrations/dealers/table/useAddonsTable';
import { Popover } from 'components/atoms/popover';

interface Props {
  addOns: AddonTableRow[];
  isLoading: boolean;
  dealerAdminForm: UseFormReturn<DealerAdminFormValues>;
}

export const AddonsTable: React.FC<Props> = ({ isLoading, addOns, dealerAdminForm }) => {
  const { handleDeleteClick, columns } = useAddonsTable({ dealerAdminForm });

  return (
    <MaterialReactTable
      columns={columns}
      enableRowActions
      renderRowActions={({ row }): React.ReactNode => (
        <Popover
          data-cy={`dealerAddons_table_row-${row.index}_popover`}
          icon={<MoreVertIcon />}
          content={
            <Button
              variant="outlined"
              color="error"
              onClick={(): void =>
                handleDeleteClick(dealerAdminForm.getValues('dealer'), addOns[row.index].name)
              }
            >
              Delete
            </Button>
          }
        />
      )}
      data={addOns}
      enableColumnActions={false}
      enableTopToolbar
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableHiding={false}
      enableFilters={false}
      positionActionsColumn="last"
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
        },
      }}
      initialState={{
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'name', desc: false }],
      }}
      state={{
        isLoading,
      }}
    />
  );
};
