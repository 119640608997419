import { useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import {
  AddonTableRow,
  DealerAdminFormValues,
  addonTableColumns,
} from 'pages/administration/dealers/dealer-admin-form/DealerAdminFormService';

import { Dealer } from 'services/DealerApiService';

interface HookProps {
  dealerAdminForm: UseFormReturn<DealerAdminFormValues>;
}

interface HookResult {
  handleDeleteClick: (dealer: Dealer, name: string) => void;
  columns: ColumnDef<Partial<AddonTableRow>>[];
}

export const useAddonsTable = ({ dealerAdminForm }: HookProps): HookResult => {
  const handleDeleteClick = useCallback((dealer: Dealer, name: string): void => {
    const updatedDealer = {
      ...dealer,
      addons: dealer.addons.filter((addon) => addon.name !== name),
    };

    dealerAdminForm.setValue('dealer', updatedDealer);
  }, []);

  const columns = useMemo<ColumnDef<Partial<AddonTableRow>>[]>(() => addonTableColumns(), []);

  return {
    handleDeleteClick,
    columns,
  };
};
