import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';

import {
  Dealer,
  DealerLenderSettings as DealerLenderSettingsApi,
  DealerReserveOverrides,
  FeeValueType,
} from 'services/DealerApiService';

export enum DealerReserveMode {
  Flat = 0,
  SuperFlat = 1,
}

export const DealerReserveModeLabels: Record<number, string> = {
  0: 'Flat',
  1: 'Super Flat',
};

export interface ReserveOverrides {
  isRateMarkupPercent: boolean;
  defaultRateMarkup: number | undefined;
  maxMarkup: number | undefined;
  dealerSplit: number | undefined;
  percent: number | undefined;
  fee: number | undefined;
  dealerReserveMode: DealerReserveMode;
}

export interface DealerLenderSettings {
  id: string;
  lenderCode: string;
  lenderName: string;
  newMoneyFactor: ReserveOverrides;
  newApr: ReserveOverrides;
  usedApr: ReserveOverrides;
  cpovApr: ReserveOverrides;
}

export interface DealerAdminFormValues {
  lenderSettings: DealerLenderSettings[];
  dealer: Dealer;
}

function mapDealerReserveMode(reserveOverrides: DealerReserveOverrides): DealerReserveMode {
  return reserveOverrides.dealerReserveValueType === FeeValueType.Amount
    ? DealerReserveMode.Flat
    : DealerReserveMode.SuperFlat;
}

function mapFormRateMarkup(rateMarkup: number | undefined, isPercent: boolean): number | undefined {
  if (rateMarkup === undefined || rateMarkup === null) {
    return undefined;
  }

  return isPercent ? +(rateMarkup * 100).toPrecision(4) : rateMarkup;
}

function mapMaxRateMarkup(rateMarkup: number | undefined, isPercent: boolean): number | undefined {
  if (rateMarkup === undefined || rateMarkup === null) {
    return undefined;
  }

  return isPercent ? +(rateMarkup / 100).toPrecision(4) : rateMarkup;
}

function mapReserveOverrides(
  reserveOverrides: DealerReserveOverrides,
  isRateMarkupPercent: boolean
): ReserveOverrides {
  return {
    isRateMarkupPercent,
    defaultRateMarkup: mapFormRateMarkup(reserveOverrides.defaultRateMarkup, isRateMarkupPercent),
    maxMarkup: mapFormRateMarkup(reserveOverrides.maxRateMarkup, isRateMarkupPercent),
    dealerSplit: reserveOverrides.dealerSplit ? reserveOverrides.dealerSplit * 100 : undefined,
    percent:
      reserveOverrides.dealerReserveValueType === FeeValueType.Percentage
        ? (reserveOverrides.dealerReserveValue ?? 0) * 100
        : undefined,
    fee:
      reserveOverrides.dealerReserveValueType === FeeValueType.Amount
        ? reserveOverrides.dealerReserveValue
        : undefined,
    dealerReserveMode: mapDealerReserveMode(reserveOverrides),
  };
}

export function mapFormLenderSettings(lenderSettings: {
  [k: string]: DealerLenderSettingsApi;
}): DealerLenderSettings[] {
  if (!lenderSettings) {
    return [];
  }

  const entries = Object.entries(lenderSettings).map((x) => {
    return {
      id: x[0],
      lenderCode: x[0],
      lenderName: x[1].lenderName,
      newMoneyFactor: mapReserveOverrides(x[1].markups.lease.newMoneyFactor, false),
      newApr: mapReserveOverrides(x[1].markups.loan.newApr, true),
      usedApr: mapReserveOverrides(x[1].markups.loan.usedApr, true),
      cpovApr: mapReserveOverrides(x[1].markups.loan.cpovApr, true),
    };
  });

  return entries;
}

function mapDealerReserveValue(reserveOverrides: ReserveOverrides): number | undefined {
  switch (reserveOverrides.dealerReserveMode) {
    case DealerReserveMode.Flat:
      return reserveOverrides.fee;
    case DealerReserveMode.SuperFlat:
      return (reserveOverrides.percent ?? 0) / 100;
    default:
      return undefined;
  }
}

function mapDealerReserveValueType(reserveOverrides: ReserveOverrides): FeeValueType {
  return reserveOverrides.dealerReserveMode === DealerReserveMode.SuperFlat
    ? FeeValueType.Percentage
    : FeeValueType.Amount;
}

function mapDealerReserveOverrides(reserveOverrides: ReserveOverrides): DealerReserveOverrides {
  return {
    defaultRateMarkup: mapMaxRateMarkup(
      reserveOverrides.defaultRateMarkup,
      reserveOverrides.isRateMarkupPercent
    ),
    maxRateMarkup: mapMaxRateMarkup(
      reserveOverrides.maxMarkup,
      reserveOverrides.isRateMarkupPercent
    ),
    dealerSplit: reserveOverrides.dealerSplit ? reserveOverrides.dealerSplit / 100 : undefined,
    dealerReserveValue: mapDealerReserveValue(reserveOverrides),
    dealerReserveValueType: mapDealerReserveValueType(reserveOverrides),
  };
}

function mapLenderSetting(lenderSettings: DealerLenderSettings): DealerLenderSettingsApi {
  return {
    lenderName: lenderSettings.lenderName,
    markups: {
      lease: {
        newMoneyFactor: mapDealerReserveOverrides(lenderSettings.newMoneyFactor),
      },
      loan: {
        newApr: mapDealerReserveOverrides(lenderSettings.newApr),
        usedApr: mapDealerReserveOverrides(lenderSettings.usedApr),
        cpovApr: mapDealerReserveOverrides(lenderSettings.cpovApr),
      },
    },
  };
}

export function getDefaultLeaseMileageOptions(): MultiSelectOptions[] {
  return [
    {
      label: '5000',
      value: 5000,
      isChecked: false,
    },
    {
      label: '7500',
      value: 7000,
      isChecked: false,
    },
    {
      label: '10000',
      value: 10000,
      isChecked: false,
    },
    {
      label: '12000',
      value: 12000,
      isChecked: false,
    },
    {
      label: '15000',
      value: 15000,
      isChecked: false,
    },
  ];
}

export interface AddonTableRow {
  name: string;
}

interface ColumnData {
  accessorKey: string;
  header: string;
}

function createAddonTableColumns(columns: ColumnData[]): ColumnDef<Partial<AddonTableRow>>[] {
  const addons: ColumnDef<Partial<AddonTableRow>>[] = [];

  for (const column of columns) {
    addons.push({
      accessorKey: column.accessorKey,
      header: column.header,
    });
  }

  return addons;
}

export function addonTableColumns(): ColumnDef<Partial<AddonTableRow>>[] {
  const rows: ColumnData[] = [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'type', header: 'Type' },
    { accessorKey: 'price', header: 'Price' },
    { accessorKey: 'wholesale', header: 'Wholesale' },
    { accessorKey: 'includeByDefault', header: 'Include By Default' },
    { accessorKey: 'vehicleState', header: 'Vehicle State' },
    { accessorKey: 'dealType', header: 'Deal Type' },
  ];

  return createAddonTableColumns(rows);
}

export function getDefaultLeaseTermOptions(): MultiSelectOptions[] {
  return [
    {
      label: '12',
      value: 12,
      isChecked: false,
    },
    {
      label: '24',
      value: 24,
      isChecked: false,
    },
    {
      label: '27',
      value: 27,
      isChecked: false,
    },
    {
      label: '36',
      value: 36,
      isChecked: false,
    },
    {
      label: '39',
      value: 39,
      isChecked: false,
    },

    {
      label: '42',
      value: 42,
      isChecked: false,
    },

    {
      label: '48',
      value: 48,
      isChecked: false,
    },

    {
      label: '54',
      value: 54,
      isChecked: false,
    },

    {
      label: '60',
      value: 60,
      isChecked: false,
    },
  ];
}

export function getDefaultLoanTermOptions(): MultiSelectOptions[] {
  return [
    {
      label: '24',
      value: 24,
      isChecked: false,
    },
    {
      label: '36',
      value: 36,
      isChecked: false,
    },
    {
      label: '44',
      value: 44,
      isChecked: false,
    },
    {
      label: '48',
      value: 48,
      isChecked: false,
    },
    {
      label: '54',
      value: 54,
      isChecked: false,
    },
    {
      label: '60',
      value: 60,
      isChecked: false,
    },
    {
      label: '66',
      value: 66,
      isChecked: false,
    },
    {
      label: '72',
      value: 72,
      isChecked: false,
    },
    {
      label: '75',
      value: 75,
      isChecked: false,
    },
    {
      label: '84',
      value: 84,
      isChecked: false,
    },
    {
      label: '96',
      value: 96,
      isChecked: false,
    },
  ];
}

export function mapLenderSettings(
  lenderSettings: DealerLenderSettings[]
): Map<string, DealerLenderSettingsApi> {
  const settings = new Map<string, DealerLenderSettingsApi>();

  lenderSettings.forEach((x) => settings.set(x.id, mapLenderSetting(x)));

  return settings;
}

export const getReserveModeOptions = (): { label: string; value: number }[] => {
  return Object.entries(DealerReserveMode)
    .filter((mode) => !isNaN(Number(mode[1])))
    .map((mode) => {
      return { label: DealerReserveModeLabels[Number(mode[1])], value: Number(mode[1]) };
    });
};

export const getLenderDisplayName = (lenderName: string, lenderCode: string): string => {
  return lenderName === lenderCode ? lenderName : `${lenderName} - (${lenderCode})`;
};
