export function getCurrentDealershipApiUrl(): string {
  return '/Dealer/CurrentDealership';
}

export function getDealersApiUrl(dealerGroupPublicId?: string): string {
  const queryString = `?dealerGroupPublicId=${dealerGroupPublicId}`;

  return `/api/dealers${dealerGroupPublicId ? queryString : ''}`;
}

export function getDealerApiUrl(publicId: string): string {
  return `/api/dealers/${publicId}`;
}

export function getPutDealerApiUrl(): string {
  return `/api/dealers/`;
}

export enum TaxCreditTo {
  TradeEquity = 'TradeEquity',
  CustomerAllowance = 'CustomerAllowance',
  NoCredit = 'NoCredit',
  Payoff = 'Payoff',
}

export enum DealerMode {
  Profit = 'Profit',
  Volume = 'Volume',
  InventoryAge = 'InventoryAge',
}

export interface DealerDefaults {
  leaseTaxCreditTo: TaxCreditTo;
  loanTaxCreditTo: TaxCreditTo;
  payAllFeesUpFront: boolean;
  leaseTerms: number[];
  leaseMileages: number[];
  loanTerms: number[];
  recommendationsPaymentRange: number;
  dealerMode: DealerMode;
}

export interface DealerAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export enum AddonType {
  Addition = 'Addition',
  ExtendedWarranty = 'ExtendedWarranty',
  InsurancePackage = 'InsurancePackage',
  ProtectionPackage = 'ProtectionPackage',
  TheftProtection = 'TheftProtection',
  Warranty = 'Warranty',
}
export enum VehicleState {
  Any = 'Any',
  New = 'New',
  Used = 'Used',
  CertifiedPreOwned = 'CertifiedPreOwned',
}

export enum DealType {
  Any = 'Any',
  New = 'Lease',
  Used = 'Loan',
}

export interface Addon {
  name: string;
  price: number;
  wholesale: number;
  includeByDefault: boolean;
  type: AddonType;
  vehicleState: VehicleState;
  dealType: DealType;
}

export enum FeeValueType {
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export interface DealerReserveOverrides {
  defaultRateMarkup: number | undefined;
  maxRateMarkup: number | undefined;
  dealerSplit: number | undefined;
  dealerReserveValue: number | undefined;
  dealerReserveValueType: FeeValueType;
  // TODO: maxAcquisitionFee: number | undefined;
}

export interface LeaseMarkup {
  newMoneyFactor: DealerReserveOverrides;
}

export interface LoanMarkup {
  newApr: DealerReserveOverrides;
  cpovApr: DealerReserveOverrides;
  usedApr: DealerReserveOverrides;
}

export interface DealerLenderSettingsMarkups {
  lease: LeaseMarkup;
  loan: LoanMarkup;
}

export interface DealerLenderSettings {
  lenderName: string;
  markups: DealerLenderSettingsMarkups;
}

export interface Dealer {
  name: string;
  code: string;
  publicId: string;
  address: DealerAddress;
  featuredLenders: string[];
  featuredLendersSettings: { [k: string]: DealerLenderSettings };
  parentGroup: string;
  parentPublicId: string;
  logoUrl: string;
  newCarCrmEmail: string;
  usedCarCrmEmail: string;
  dealOfferDisclaimer: string;
  franchiseBrands: string[];
  tradeInUrl: string;
  creditAppUrl: string;
  addons: Addon[];
  defaults: DealerDefaults;
  titleLicenseEstimate: number;
}
